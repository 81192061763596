import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import Equalizer from './Equalizer'
import moment from 'moment'
import { Date as PrismicDate } from 'prismic-reactjs'

import arrow from '../images/cta-arrow.svg'

class TrendsColumn extends PureComponent {
  constructor(props) {
    super(props)
    if (typeof window !== 'undefined') {
      this.media = window.matchMedia('screen and (min-width: 900px)')
    }
  }

  getHeaderNodes() {
    return [this.headerNode0, this.headerNode1, this.headerNode2]
  }

  getDescriptionNodes() {
    return [this.descriptionNode0, this.descriptionNode1, this.descriptionNode2]
  }

  getNodesForEqualizer(nodePrefix) {
    return Object.getOwnPropertyNames(this)
      .filter(property => property.indexOf(nodePrefix) === 0)
      .map(property => this[property])
  }

  render() {
    return (
      <>
        <Equalizer
          byRow={false}
          nodes={() => this.getNodesForEqualizer('headerNode')}
          enabled={() => (this.media ? this.media.matches : true)}
          forceGroupItemsCount
          groupItemsCount={3}
        >
          <Equalizer
            byRow={false}
            nodes={() => this.getNodesForEqualizer('descriptionNode')}
            enabled={() => (this.media ? this.media.matches : true)}
            forceGroupItemsCount
            groupItemsCount={3}
          >
            <div className="lg:flex lg:flex-wrap lg:-mx-4">
              {this.props.trends.map(
                (
                  {
                    uid,
                    title,
                    description,
                    focusArea,
                    image,
                    link,
                    linkLogo,
                    createdAt,
                  },
                  index
                ) => (
                  <div
                    key={index}
                    className={classnames(
                      'group mb-16 lg:mb-0 lg:w-1/3 flex-shrink-0 flex-no-grow mt-16 px-4',
                      {
                        'lg:mr-12':
                          (index !== this.props.trends.length % 3) === 0,
                      }
                    )}
                  >
                    <div
                      className={classnames(
                        'border-b-4',
                        `border-${focusArea._meta.uid}`
                      )}
                    >
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block no-underline text-blue-dianne"
                      >
                        <img src={image} alt="" className="block w-full" />
                      </a>
                    </div>
                    <h2
                      className="font-serif font-normal mt-10 mx-8 lg:mx-0"
                      ref={node => (this[`headerNode${index}`] = node)}
                    >
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block no-underline text-blue-dianne"
                      >
                        {title}
                      </a>
                    </h2>
                    <div className="flex items-center justify-between mt-4 mb-8 mx-8 lg:mx-0">
                      <p className="font-sans text-gothic my-0 text-sm">
                        {moment(PrismicDate(createdAt)).format('MMMM DD, YYYY')}
                      </p>
                      {(linkLogo ||
                        this.props.logos[`trend__logo--${uid}`]) && (
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block mb-1"
                        >
                          <img
                            src={
                              this.props.logos[`trend__logo--${uid}`] ||
                              linkLogo.url
                            }
                            alt=""
                            className="h-3 block"
                            style={{ opacity: 0.8 }}
                          />
                        </a>
                      )}
                    </div>
                    <p
                      className="leading-normal font-sans mx-8 lg:mx-0"
                      ref={node => (this[`descriptionNode${index}`] = node)}
                    >
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block no-underline text-blue-dianne"
                      >
                        {description}
                      </a>
                    </p>
                    <div className="mt-8 mx-8 lg:mx-0">
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="no-underline hover:underline text-gothic uppercase text-sm font-sans leading-normal"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                )
              )}
              {this.props.paginationArrowLink && (
                <div className="mt-16 px-4 flex-grow relative hidden lg:block">
                  <div className="pagination-arrow__outer">
                    <div className="pagination-arrow__inner flex items-center justify-center">
                      <Link
                        to={this.props.paginationArrowLink}
                        className="block w-24 h-24 flex items-center justify-center rounded-full border border-transparent hover:border-blue-dianne"
                      >
                        <img src={arrow} alt="" className="h-8" />
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Equalizer>
        </Equalizer>
      </>
    )
  }
}

export default TrendsColumn
