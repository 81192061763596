import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import classnames from 'classnames'

import Layout from '../components/layout'
import SEO from '../components/seo'

import TrendsColumn from '../components/TrendsColumn'
import PageHeader from '../components/PageHeader'

import arrow from '../images/cta-arrow.svg'

class TrendsPage extends React.PureComponent {
  getNodesForEqualizer(nodePrefix) {
    return Object.getOwnPropertyNames(this)
      .filter(property => property.indexOf(nodePrefix) === 0)
      .map(property => this[property])
  }

  render() {
    const trends = this.props.pageContext.trends.map(
      ({
        title,
        description,
        link,
        focusArea,
        linkLabel,
        linkLogo,
        ...node
      }) => {
        const whereToSlice = Boolean(description)
          ? description.slice(0, 160).substr(-1) === ' '
            ? 159
            : 160
          : 0
        const excerpt =
          Boolean(description) && description.length > 160
            ? description.slice(0, whereToSlice) +
              (description.slice(0, whereToSlice).substr(-1) === '.'
                ? ''
                : '...')
            : description

        return {
          uid: node._meta.uid,
          title,
          description: excerpt,
          focusArea,
          image: node.thumbnail ? node.thumbnail.url : undefined,
          link: link ? link.url : '',
          linkLabel,
          linkLogo,
          createdAt: node._meta.firstPublicationDate,
        }
      }
    )

    const { page } = this.props.pageContext

    const hasPreviousPage = this.props.pageContext.currentPage > 1
    const hasNextPage =
      this.props.pageContext.currentPage < this.props.pageContext.numPages

    const query = graphql`
      query {
        allFile(filter: { name: { glob: "trend__logo--*" } }) {
          edges {
            node {
              name
              childImageSharp {
                fixed: fixed(quality: 100, grayscale: true, height: 24) {
                  src
                }
              }
            }
          }
        }
      }
    `

    return (
      <StaticQuery
        query={query}
        render={data => {
          const logos = data.allFile.edges.reduce((all, edge) => {
            return {
              ...all,
              [edge.node.name]: edge.node.childImageSharp.fixed.src,
            }
          }, {})
          return (
            <Layout>
              <SEO title={page.title} keywords={[`longevity`]} />

              <PageHeader
                titles={[page.title]}
                richDescription={page.description}
              />

              <div className="container lg:max-w-xl mb-24 lg:mb-32 mx-auto">
                <div
                  className={classnames('mt-16', {
                    'mb-32': this.props.pageContext.numPages === 1,
                  })}
                >
                  <TrendsColumn
                    trends={trends}
                    logos={logos}
                    paginationArrowLink={
                      this.props.pageContext.numPages >
                      this.props.pageContext.currentPage
                        ? `/trends/${this.props.pageContext.currentPage + 1}`
                        : undefined
                    }
                  />
                </div>

                {this.props.pageContext.numPages && (
                  <ul
                    id="pagination"
                    className="list-reset flex items-center font-sans my-16 lg:my-24 flex-wrap justify-start px-4 lg:px-0"
                  >
                    <li className="w-1/3">
                      <Link
                        to={`/trends${
                          this.props.pageContext.currentPage === 2
                            ? ''
                            : `/${this.props.pageContext.currentPage - 1}`
                        }`}
                        className={classnames(
                          'font-serif text-base text-blue-dianne flex items-center no-underline',
                          {
                            invisible: !hasPreviousPage,
                          }
                        )}
                      >
                        <img
                          src={arrow}
                          alt=""
                          className="mr-4 lg:mr-8 h-6 svg-mirror"
                        />
                        {/* <span className="cta-ie11-fix">Previous</span>{' '} */}
                      </Link>
                    </li>
                    <li style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                      <p className="font-serif text-blue-dianne">
                        Page {this.props.pageContext.currentPage} of{' '}
                        {this.props.pageContext.numPages}
                      </p>
                    </li>
                    <li className="w-1/3">
                      <Link
                        to={`/trends/${this.props.pageContext.currentPage + 1}`}
                        className={classnames(
                          'font-serif text-base text-blue-dianne flex items-center justify-end no-underline',
                          {
                            invisible: !hasNextPage,
                          }
                        )}
                      >
                        {/* <span className="cta-ie11-fix">Next</span>{' '} */}
                        <img src={arrow} alt="" className="ml-4 g:ml-8 h-6" />
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
            </Layout>
          )
        }}
      />
    )
  }
}

export default TrendsPage
